module.exports = {
  siteTitle: 'Chris Marabate | Web Developer',
  siteDescription:
    'I am a Web Developer based in Warren, MI. I specialize in developing exceptional, high-quality, and blazing fast websites.',
  siteKeywords:
    'Chris Marabate, Chris, Marabate, cmarabate, web developer, front-end developer, ui developer, ux developer, javascript, jquery, html, css, gatsby, git',
  siteUrl: 'https://chrismarabate.com',
  siteLanguage: 'en_US',
  googleAnalyticsID: 'UA-44045336-1',
  googleVerification: 'DCl7VAf9tcz6eD9gb67NfkNnJ1PKRNcg8qQiwpbx9Lk',
  name: 'Chris Marabate',
  location: 'Warren, MI',
  email: 'cmarabate@gmail.com',
  github: 'https://github.com/cmarabate',
  twitterHandle: '@cmarabate',
  socialMedia: [
    {
      name: 'GitHub',
      url: 'https://github.com/cmarabate',
    },
    {
      name: 'Linkedin',
      url: 'https://www.linkedin.com/in/chrismarabate/',
    },
    {
      name: 'Codepen',
      url: 'https://codepen.io/cmarabate',
    },

    /*
    {
      name: 'WordPress',
      url: 'https://profiles.wordpress.org/wpdevpowers/',
    },
      {
        name: 'Instagram',
        url: '',
      },
      {
        name: 'Twitter',
        url: '',
      },

    */
  ],

  navLinks: [
    {
      name: 'About',
      url: '/#about',
    },
    {
      name: 'Experience',
      url: '/#jobs',
    },
    {
      name: 'Work',
      url: '/#projects',
    },
    {
      name: 'Contact',
      url: '/#contact',
    },
  ],

  navHeight: 100,
  greenColor: '#b215c4',
  navyColor: '#880f99',
  darkNavyColor: '#5c0866',

  srConfig: (delay = 200) => ({
    origin: 'bottom',
    distance: '20px',
    duration: 500,
    delay,
    rotate: { x: 0, y: 0, z: 0 },
    opacity: 0,
    scale: 1,
    easing: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
    mobile: true,
    reset: false,
    useDelay: 'always',
    viewFactor: 0.25,
    viewOffset: { top: 0, right: 0, bottom: 0, left: 0 },
  }),
};
