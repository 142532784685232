import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <g id="B" transform="translate(22.000000, 63.000000)">
        <path
          d="M18.36-5.35L21.35-2.71L21.35-2.71Q19.87-1.26 17.99-0.54L17.99-0.54L17.99-0.54Q16.12 0.18 13.95 0.18L13.95 0.18L13.95 0.18Q11.56 0.18 9.56-0.69L9.56-0.69L9.56-0.69Q7.55-1.55 6.05-3.25L6.05-3.25L6.05-3.25Q4.55-4.95 3.70-7.52L3.70-7.52L3.70-7.52Q2.85-10.08 2.85-13.44L2.85-13.44L2.85-13.44Q2.85-16.80 3.70-19.35L3.70-19.35L3.70-19.35Q4.55-21.90 6.05-23.59L6.05-23.59L6.05-23.59Q7.55-25.29 9.58-26.18L9.58-26.18L9.58-26.18Q11.60-27.06 13.95-27.06L13.95-27.06L13.95-27.06Q16.12-27.06 17.99-26.34L17.99-26.34L17.99-26.34Q19.87-25.62 21.35-24.17L21.35-24.17L18.36-21.50L18.36-21.50Q17.45-22.55 16.35-23.02L16.35-23.02L16.35-23.02Q15.25-23.49 14.06-23.49L14.06-23.49L14.06-23.49Q12.72-23.49 11.51-22.98L11.51-22.98L11.51-22.98Q10.30-22.47 9.34-21.26L9.34-21.26L9.34-21.26Q8.38-20.05 7.84-18.14L7.84-18.14L7.84-18.14Q7.30-16.22 7.26-13.44L7.26-13.44L7.26-13.44Q7.30-10.73 7.82-8.82L7.82-8.82L7.82-8.82Q8.35-6.90 9.27-5.69L9.27-5.69L9.27-5.69Q10.19-4.48 11.36-3.92L11.36-3.92L11.36-3.92Q12.54-3.36 13.84-3.36L13.84-3.36L13.84-3.36Q15.14-3.36 16.28-3.83L16.28-3.83L16.28-3.83Q17.42-4.30 18.36-5.35L18.36-5.35ZM39.89-8.42L47.77-26.63L52.18-26.63L52.18 0L47.77 0L47.77-18.32L41.81-3.50L37.98-3.50L31.98-18.32L31.98 0L27.57 0L27.57-26.63L31.98-26.63L39.89-8.42Z"
          fill="#e764ff"
        />
      </g>
      <path
        stroke="#e764ff"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 50, 5
                  L 11, 27
                  L 11, 72
                  L 50, 95
                  L 89, 73
                  L 89, 28 z"
      />
    </g>
  </svg>
);

export default IconLoader;
