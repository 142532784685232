import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import sr from '@utils/sr';
import { srConfig, email } from '@config';
import styled from 'styled-components';
import { theme, mixins, media, Section, Heading } from '@styles';
const { colors, fontSizes, fonts } = theme;

const StyledContainer = styled(Section)`
text-align: center;
max-width: 600px;
margin: 0 auto 100px;
a {
  ${mixins.inlineLink};
}
`;
const StyledHeading = styled(Heading)`
display: block;
color: ${colors.green};
font-size: ${fontSizes.md};
font-family: ${fonts.SFMono};
font-weight: normal;
margin-bottom: 20px;
justify-content: center;
${media.desktop`font-size: ${fontSizes.sm};`};
&:before {
  bottom: 0;
  font-size: ${fontSizes.sm};
  ${media.desktop`font-size: ${fontSizes.smish};`};
}
&:after {
  display: none;
}
`;
const StyledTitle = styled.h4`
margin: 0 0 20px;
font-size: 60px;
${media.desktop`font-size: 50px;`};
${media.tablet`font-size: 40px;`};
`;
const StyledEmailLink = styled.a`
${mixins.bigButton};
margin-top: 50px;
`;

const Contact = ({ data }) => {
  const { frontmatter, html } = data[0].node;
  const { title } = frontmatter;
  const revealContainer = useRef(null);
  useEffect(() => sr.reveal(revealContainer.current, srConfig()), []);

  return (
    <StyledContainer id="contact" ref={revealContainer}>
    <StyledHeading>What&apos;s Next?</StyledHeading>

    <StyledTitle>{title}</StyledTitle>

    <div dangerouslySetInnerHTML={{ __html: html }} />

        <form name="contact" className="boilerform" netlify>
        <p>
        <label>Name: <input type="text" name="name" /></label>
        </p>
        <p>
        <label>Email: <input type="email" name="email" /></label>
        </p>
        <p>Message</p>
        <p>
        <textarea name="contact-message" id="contact-message"></textarea>
        </p>
        <p>
        <StyledEmailLink href={`mailto:${email}`} target="_blank" rel="nofollow noopener noreferrer">Send</StyledEmailLink>
        </p>
        </form>

        </StyledContainer>
        );
      };

      Contact.propTypes = {
        data: PropTypes.array.isRequired,
      };

      export default Contact;
